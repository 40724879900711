.App {
  text-align: center;
  background-color: #111111;
  color: white;
}

.App-logo {
  height: 28px;
  width: auto;
  pointer-events: none;
}

.App-header {
  background-color: #111111;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
}

.Splash-hero {
  background: linear-gradient(135deg, #0d0d0d 0%, #1a1a1a 50%, #1a1a1a 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Splash-hero h1 {
  margin: 0;
  font-size: 4rem;
}

.Splash-hero h2 {
  margin: 0;
  font-size: 2rem;
}

.Splash-hero button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #61dafb;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.Splash-hero button:hover {
  background-color: #21a1f1;
  transform: scale(1.05);
}

.Splash-hero h1, .Splash-hero h2, .Splash-hero button {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Services {
  background: linear-gradient(135deg, #1a1a1a 0%, #1a1a1a 50%, #0d0d0d 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 40px 20px;
}

.Services h2 {
  margin: 0 0 40px 0; /* Increased bottom margin */
  font-size: 3rem;
  text-align: center;
}

.Services video {
  width: 100%;
  max-width: 600px;
  border: 2px solid #61dafb;
  border-radius: 10px;
  margin-top: 20px;
}

.Services .video-placeholder {
  width: 100%;
  max-width: 600px;
  height: auto;
  border: 2px solid #61dafb;
  border-radius: 10px;
  background: url('./logo.svg') center center no-repeat;
  background-size: contain;
}

@media (min-width: 768px) {
  .Services h2 {
    margin-bottom: 60px; /* Further increased bottom margin for larger screens */
  }
}

.Footer {
  background-color: #111111;
  padding: 20px;
  text-align: center;
  font-size: 1rem;
  color: white;
  animation: fadeIn 2s ease-in-out;
}

.Footer-logo {
  height: 28px;
  width: auto;
  pointer-events: none;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
